.card {
	width: 11vw;
	height: 17vw;
	transition: 0.3s;
	transform-style: preserve-3d;
	position: relative;
	cursor: pointer;

	img {
		border-radius: 15px;
		height: 100%;
	}

	.card-face {
		backface-visibility: hidden;
		position: absolute;
		margin-left: -15px;
		width: 100%;
		height: 100%;
		&.card-back-face {
			transform: rotateY(180deg);
		}
	}

	&.is-flipped {
		transform: rotateY(180deg);
	}

	&.is-inactive {
		visibility: hidden;
		opacity: 0;
	}
}
