
.xp-bar {
	.stat-bar {
		--foreground-color: var(--color-accent-primary);
		border-radius: 8px;

		padding: 2.5px 8px 0;
	}

	.current-level,
	.next-level {
		font-weight: 700;
		font-size: 18px;
	}
}

.xp-text {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 10px;

	font-size: 18px;
	color: var(--color-accent-dark);
	letter-spacing: 0;
	font-weight: 700;

	strong {
		color: $white;
	}
}