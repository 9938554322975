.scoreboard {
	font-family: 'Inter';
	font-style: normal;
	width: 550px;
	.page-title {
		width: 1002px;
		height: 34px;
		left: 328px;
		top: 98px;

		font-weight: 500;
		font-size: 24px;
		line-height: 100.02%;
		/* or 24px */

		color: #ffffff;
	}

	.restart {
		width: 130px;
		height: 43.33px;
		background: #ffffff;
		border-radius: 17px;
		margin-bottom: 37px;
		font-size: 16px;
	}
	.moves {
		margin-bottom: 20px;
		
		.moves_label {
			max-width: 120px;
			.label {
				width: 66px;
				height: 18px;
				left: 1165px;
				top: 297px;

				font-family: 'Inter';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 111.02%; /* or 20px */
				color: #494949;
			}
			.value {
				width: 39px;
				height: 37px;
				left: 1237px;
				top: 271px;

				font-family: 'Inter';
				font-style: normal;
				font-weight: 700;
				font-size: 48px;
				line-height: 111.02%;
				/* or 53px */

				text-align: center;

				color: #ffffff;
			}
		}
	}
}
