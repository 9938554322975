%reset_button {
	padding: 0;
	background-color: transparent;
	border: 0;
}

%base_button {
	position: relative;
	display: inline-block;

	@extend %text_button;
	background: $white;
	color: $color-text-secondary;
	font-weight: $font-weight-bold;
	padding: 5px 11px;
	border-radius: 6px;
	
	text-align: center;

	cursor: pointer;
	
	transition: background-color 0.3s ease, transform 0.3s ease;
	transform: scale(1);

	span {
		color: inherit;
	}

	&:hover {
		color: $color-text-secondary;
		transform: scale(1.05);
	}

	&.big {
		padding: 15px 25px;
		font-size: 18px;
	}

	&.disabled {
		opacity: 0.25;
		pointer-events: none;
	}
}

%arrow_button {
	padding-right: 33px;

	.arrow-icon {
		position: absolute;
		top: 50%;
		right: 14px;
		transform: translate(0, -50%);
	}

	&.big {
		padding-right: 55px;

		.arrow-icon {
			right: 25px;
			width: 15px;
			height: 15px;
		}
	}
}

[class^=button] {
	@extend %base_button;

	&[class*=-arrow] {
		@extend %arrow_button;
	}
}
