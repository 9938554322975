html {
	font-size: 100%;
	background-size: 100% auto;
	background-color: $color-background-main;
	overflow: hidden;
}

body {
	position: relative;
	-webkit-overflow-scrolling: touch;
	background-color: $color-background-main;
	overflow: hidden;
	z-index: 1;
	
	&.opened {
		overflow: hidden;
	}
}

.main-content {
	position: relative;
	z-index: 1;
}

img {
	max-width: 100%;
	height: auto;
}

*, ::before, ::after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*:focus {
	text-decoration: none;
}

button {
	border: none;
}

.container, .container-fluid {
	width: calc(100% - var(--container-gutter));
}

.section-block {
	margin-top: var(--section-spacing-top);
	margin-bottom: var(--section-spacing-bottom);

	&.with-background {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: var(--section-spacing-top);
		padding-bottom: var(--section-spacing-bottom);
	}
}



*::-webkit-scrollbar {
	width: 5px;
}

*::-webkit-scrollbar-track {
	background: rgba($white, 0.2);
	border-radius: 0;
}

*::-webkit-scrollbar-thumb {
	background-color: $black;
	border-radius: 0;
}


.page.enter,
.page.appear {
	*::-webkit-scrollbar,
	*::-webkit-scrollbar-track,
	*::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
}