.memory {
	font-family: 'Inter';
	font-style: normal;
	.page-title {
		width: 1002px;
		height: 34px;
		left: 328px;
		top: 98px;

		font-weight: 500;
		font-size: 24px;
		line-height: 100.02%;
		/* or 24px */

		color: #ffffff;
	}

	.sub-title {
		width: 1002px;
		height: 174px;
		left: 324px;
		top: 138px;

		font-weight: 800;
		font-size: 74px;
		line-height: 100.02%;
		/* or 74px */

		color: #ffffff;
	}
	.footer {
		max-width: 950px;
		margin: 0;
	}
	.container {
		padding: 12px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(3, 1fr);
		gap: 1rem;
		height: auto;
		max-width: 950px;
		margin: 0 auto;
	}
}
