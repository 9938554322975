.lined-title {
	display: flex;
	align-items: center;
	font-weight: $font-weight-thin;

	&.caps {
		text-transform: uppercase;
	}

	.text {
		flex: 0 0 auto;
	}

	&:after {
		content: '';
		display: block;
		width: 100%;
		flex: 1 1 100%;
		margin-left: 20px;
		border-bottom: 1px dashed rgba($white, 0.55);
	}

	&.small {
		font-size: 12px;
		line-height: 1.2;
	}

	&.regular {
		font-size: 20px;
		line-height: 1.2;
	}

	&.large {
		font-size: 34px;
		line-height: 34px;
	}
}