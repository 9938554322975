.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: var(--nav-width);
	height: 100%;
	z-index: 10;
	transition: transform 0.6s $easeOutCubic;
	
	height: calc(var(--vh, 1vh) * 100);
	padding: 30px 20px;
	display: flex;
	flex-direction: column;

	text-align: center;

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		filter: drop-shadow(0 0 10px rgba($black, 0.6));

		svg {
			width: 100%;
			height: 100%;
		}
	}

	ul, li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.menu-item {
		font-size: 30px;

		a {
			opacity: 0.25;
			transition: opacity 0.3s $easeInOutCubic;
			
			&:hover {
				opacity: 0.8;
			}
			
			&.active {
				opacity: 1;
			}
		}

		& + .menu-item {
			margin-top: 20px;
		}
	}

	&.hidden {
		transform: translate(-200px, 0%);
		transition: transform 0.3s $easeInCubic;
	}
	
	a {
		color: $color-text-primary;
	}

	.home-button {
		position: absolute;
		top: 70%;
		left: 76%;
		
		width: 100px;
		height: 100px;

		transform: translate(-50%, 0);
	}

	.profile-button {
		margin-top: auto;
		text-transform: uppercase;
		font-size: 14px;
		text-decoration: none;
		line-height: 1;
		margin-bottom: 5px;

		.avatar {
			--width: 40px;
			transition: transform 0.3s ease;
			transform: scale(1);
			justify-content: center;
		}

		.level-badge {
			margin: 5px 0;
		}

		&:hover {
			.avatar {
				transform: scale(1.1);
			}
		}
	}

	.logout-button {
		font-size: 60%;
		cursor: pointer;
	}
}