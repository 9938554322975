.xp-floaters {
	position: relative;
	width: 100%;

	.xp-floater {
		position: absolute;
		bottom: calc(100% + 5px);
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;

		animation: xp-visuals-fade-in 1.6s $easeInOutSine forwards;

		font-size: 10px;
		color: white;
		font-weight: $font-weight-semibold;

		text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

		pointer-events: none;
	}
}

@keyframes xp-visuals-fade-in {
	0% {
		opacity: 0;
		transform: translate(0, 0px);
	}
	30% {
		opacity: 1;
	}
	60% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translate(0, -2em);
	}
}