.debug-overlay {
	position: fixed;
	top: 0;
	left: 0;
	font-size: 12px;
	z-index: 999999;

	transform: translate(-100%, 0);
	transition: transform 0.3s ease;

	--blur-amount: 10px;
	background: rgba($color-background-main, 0.7);
	-webkit-backdrop-filter: blur(var(--blur-amount));
	-moz-backdrop-filter: blur(var(--blur-amount));
	backdrop-filter: blur(var(--blur-amount));

	padding: 5px 8px;

	&:after {
		content: 'D';
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top:0;
		left: 100%;
		font-size: 8px;

		width: 15px;
		height: 15px;

		--blur-amount: 10px;
		background: rgba($color-background-main, 0.7);
		-webkit-backdrop-filter: blur(var(--blur-amount));
		-moz-backdrop-filter: blur(var(--blur-amount));
		backdrop-filter: blur(var(--blur-amount));
	}

	&:hover {
		transform: translate(0, 0);
	}

	.debug-calls {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	table {
		width: 100%;

		td {
			padding: 3px 6px;
			text-align: center;
		}
	}
}