.match-card {
	position: relative;
	max-width: 230px;

	.match-date {
		position: absolute;
		top: 5px;
		left: -10px;
		z-index: 1;
		background: $black;
		font-weight: $font-weight-black;
		text-transform: uppercase;
		text-align: center;
		display: flex;
		flex-direction: column;
		line-height: 1;
		align-items: center;
		justify-content: center;
		width: 45px;
		height: 40px;

		.day {
			font-size: 10px;
		}

		.date {
			font-size: 20px;
		}
	}

	.wrapper {
		overflow: hidden;
		border-radius: 12px;
		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4);
		border: 1px solid rgba(#959494, 0.5);

		.top {
			position: relative;

			.bg {
				width: 100%;
				height: 100%;

				img {
					width: 100%;
					aspect-ratio: 230 / 128;
				}
			}

			&:before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 0;
				background: linear-gradient(to top, rgba(#272B31, 1) 0%, rgba(#272B31, 0) 50%);
			}
			
			.text {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				display: flex;
				padding: 7px 13px;
				font-size: 24px;
				font-weight: $font-weight-bold;
				line-height: 1;
				justify-content: space-between;
				text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
			}
		}

		.bottom {
			display: flex;
			border-top: 1px solid rgba($white, 0.5);
			padding: 10px 13px 10px;
			justify-content: space-between;
			align-items: center;
			background: rgba(#1D1B1B, 0.8);

			font-weight: $font-weight-thin;
			font-size: 19px;

			small {
				font-size: 14px;
				vertical-align: middle;
			}
			
			.score {
				font-size: 20px;
			}
		}
	}
}