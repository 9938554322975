.store {
	position: relative;
	z-index: 0;

	--slide-transition-duration: 0.6s;

	> .loading {
		height: 100vh;
	}

	.bgs {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: grid;
		grid-template: 1fr / 1fr;
		z-index: -1;
		overflow: hidden;
	
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, rgba(#2E2D36, 1) 0%, rgba(#2E2D36, 0) 50%), linear-gradient(to left, rgba(#2E2D36, 1) 0%, rgba(#2E2D36, 0) 25%);
			pointer-events: none;
			z-index: 1;
		}

		.bg {
			width: 100%;
			height: 100%;
			grid-row: 1;
			grid-column: 1;

			opacity: 0;
			transition: opacity var(--slide-transition-duration) ease;

			&.active {
				opacity: 1;
			}

			img {
				width: 100%;
				height: 100%;
				max-width: none;
				object-fit: cover;
			}
		}
	}

	.slide {
		position: relative;
		width: 100%;
		height: 100%;

		display: grid;
		align-items: center;

		opacity: 0;
		pointer-events: none;
		transition: opacity var(--slide-transition-duration) ease;

		.content {
			max-width: 400px;
		}

		.title {
			font-size: 74px;
			font-weight: $font-weight-black;
			line-height: 70px;
			text-transform: uppercase;
			margin-bottom: 0.25em;
		}

		.text {
			font-size: 18px;
			font-weight: $font-weight-medium;
			letter-spacing: 0.1em;
			line-height: 30px;
			margin-bottom: 25px;
		}

		&.active {
			opacity: 1;
			pointer-events: all;
		}
	}

	.sidebar {
		--width: 700px;
		--blur-amount: 42px;
		--padding: 60px 30px 40px;
		background: rgba($color-background-primary, 0.1);
		transition: width 0.6s $easeInOutCubic, margin 0.6s $easeInOutCubic;
		margin-left: 0px;
	}

	.store-section + .store-section {
		margin-top: 60px;
	}

	.store-section {
		.store-head {
			display: flex;
			align-items: center;
		}

		.categories-filter {
			margin-left: auto;
			display: flex;
			align-items: center;
			font-size: 22px;
			position: relative;
			z-index: 2;

			.categories-label {
				margin-right: 30px;
			}

			.toggle-btn {
				@extend %reset_button;
				font-weight: $font-weight-bold;
				color: $color-text-primary;
				display: flex;
				align-items: center;

				svg {
					margin-left: 10px;
					width: 15px;
					height: 15px;

					transform: rotate(var(--arrow-rotation, -180deg));
					transition: 0.5s transform ease-out;
					transform-origin: center center;
				}

				&.expanded svg {
					--arrow-rotation: 0deg;
				}
			}

			.categories-list {
				$categories-list-border-radius: 15px;
				
				@extend %reset_list;
				position: absolute;
				top: calc(100% + 20px);
				right: 0;
				background-color: rgba($black, 0.4);
				backdrop-filter: blur(var(--blur-amount));
				border-radius: $categories-list-border-radius;
				width: max-content;

				font-size: 22px;
				padding: 0.5em 0;

				button {
					@extend %reset_button;
					width: 100%;
					text-align: right;
					padding: 0.5em 1.5em;
					color: $color-text-primary;

					&.active {
						font-weight: $font-weight-bold;
					}
				}

				&,
				&.exit-done {
					display: none;
				}

				&.enter {
					clip-path: inset(0px 0px 100% 0px round #{$categories-list-border-radius});
					display: revert;
				}

				&.enter-active {
					clip-path: inset(0px 0px 0px 0px round #{$categories-list-border-radius});
					transition: 1s clip-path $easeOutCubic;
				}

				&.enter-done {
					display: revert;
				}

				&.exit {
					clip-path: inset(0px 0px 0px 0px round #{$categories-list-border-radius});
					display: revert;
				}

				&.exit-active {
					clip-path: inset(0px 0px 100% 0px round #{$categories-list-border-radius});
					transition: 1s clip-path $easeOutCubic;
				}
			}
		}
		
		.title {
			font-size: 26px;
			font-weight: $font-weight-semibold;
			letter-spacing: 0.1em;
			line-height: 1;
			text-transform: uppercase;
			
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.view-all {
				font-size: 12px;
				text-transform: none;
				line-height: 24px;
				cursor: pointer;
				opacity: 1;
				transition: opacity 0.3s ease;
			}
		}

		.products {
			display: grid;
			gap: 50px;
			grid-template-columns: 165px 165px 165px;
			padding-top: 65px;
			padding-left: 20px;
		}

		.product {
			border-radius: 12px;
			padding: 12px;
			background: rgba(#434450, 0.5);

			font-size: 12px;
			line-height: 15px;
			display: flex;
			flex-direction: column;

			transform: translate(0, 0);
			transition: transform 0.3s ease;
			cursor: pointer;

			.image {
				max-width: 100%;
				max-height: 120px;
				margin-top: -60px;
				margin-bottom: 15px;
				align-self: center;

				transform: translate(0, 0);
				transition: transform 0.3s ease;

				img {
					max-height: 100%;
				}
			}

			.name {
				font-weight: $font-weight-semibold;
				margin-bottom: 5px;
			}

			.variations {
				color: #919191;
				margin-bottom: 5px;
			}

			.price {
				display: flex;
				gap: 5px;
				font-weight: $font-weight-semibold;

				.sale-price + .regular-price {
					color: #919191;
					text-decoration: line-through;
				}
			}

			&:hover {
				transform: translate(0, 15px);

				.image {
					transform: translate(0, -10px);
				}
			}

			&.enter {
				opacity: 0;
				translate: 0 40px;
			}

			&.enter-active {
				opacity: 1;
				translate: 0;
				transition: 0.5s opacity $easeOutCubic, 0.5s translate $easeOutCubic;
			}

			&.exit {
				opacity: 1;
			}

			&.exit-active {
				opacity: 0;
				transition: 0.5s opacity $easeOutCubic;
			}
		}
	}

	&.enter,
	&.appear {
		.bgs {
			opacity: 0;
		}

		.slides .title,
		.slides .text,
		.slides [class^=button] {
			opacity: 0;
			transform: translate(0, 40px);
		}

		.slides .text {
			--delay: 0.1s;
		}

		.slides [class^=button] {
			--delay: 0.2s;
		}
	}

	&.enter-active,
	&.appear-active {
		.bgs {
			opacity: 1;
		}

		.slides .title,
		.slides .text,
		.slides [class^=button] {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}
	}

	.sidebar.enter,
	.sidebar.appear {
		.store-section .title,
		.products .product {
			opacity: 0;
			transform: translate(0, 40px);
		}

		.store-section .title {
			--delay: 0.2s;
		}

		@for $i from 1 to 13 {
			.products .product:nth-child(#{$i}) {
				--delay: #{$i * 0.1s + 0.3s};
			}
		}
	}

	.sidebar.enter-active,
	.sidebar.appear-active {
		.store-section .title,
		.products .product {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}
	}
}