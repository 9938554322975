.match {
	padding-left: 0;
	position: relative;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	overflow: hidden;
	will-change: transform;
	z-index: 1;

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: var(--background-image);
		background-size: cover;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			--blur-amount: 10px;

			background: rgba($color-background-main, 0.7);
			-webkit-backdrop-filter: blur(var(--blur-amount));
			-moz-backdrop-filter: blur(var(--blur-amount));
			backdrop-filter: blur(var(--blur-amount));
			border-left: 1px solid rgba($white, 0.25);
			z-index: 1;
		}
	}
	
	.close-match {
		position: absolute;
		top: 120px;
		left: 35px;
		white-space: nowrap;
		text-decoration: none;
		color: white;

		transform-origin: top left;
		transform: scale(calc(var(--width-no-unit-body, 1920) / 1920));

		transition: opacity 0.3s $easeInOutCubic;
		font-size: 14px;
		text-transform: uppercase;
		cursor: pointer;
		z-index: 9999;

		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		filter: drop-shadow(0 0 5px rgba(0, 0, 0, 1));

		opacity: 0.2;
		
		.times {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $white;
			font-size: 28px;
			line-height: 1;
			width: 34px;
			height: 34px;
			padding-bottom: 4px;
			vertical-align: middle;
			margin-right: 10px;
		}

		&:hover {
			opacity: 1;
		}
	}

	iframe {
		border: none;
	}

	.video-wrapper {
		position: relative;
		flex: 1 1 auto;
		height: 100%;
	}

	.video-player-menu {
		position: absolute;
		right: 40px;
		top: 50%;
		translate: 0 -50%;

		display: flex;
		flex-direction: column;
		gap: 30px;
		opacity: 0;
		transition: 0.5s opacity ease-out;

		&.visible {
			opacity: 1;
		}

		button {
			@extend %reset_button;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background-color: #101A2B;
			color: $white;
			display: flex;
			opacity: 0.5;
			transition: 0.3s opacity ease-out;

			&:hover {
				opacity: 0.9;
			}

			svg {
				height: 50%;
				margin: auto;
			}
		}
	}

	.chat-wrapper {
		&.enter {
			translate: 100% 0;
		}
		
		&.enter-active {
			translate: 0 0;
			transition: 1s translate $easeOutCubic;
		}

		&.exit {
			translate: 0 0;
		}

		&.exit-active {
			translate: 100% 0;
			transition: 1s translate $easeOutCubic;
		}
	}

	.prediction {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 350px;

		.expand-button {
			@extend %reset_button;
			padding: 10px 0;
			width: 100%;
			color: $white;
			background-color: #29292B;

			svg {
				transition: 0.3s scale ease-out;
			}

			&.expanded svg {
				scale: 1 -1;
			}
		}

		.poll-wrapper {
			background-color: #1f1f20;

			&.enter {
				clip-path: inset(100% 0% 0% 0%);
			}
			
			&.enter-active {
				clip-path: inset(0% 0% 0% 0%);
				transition: 0.7s clip-path ease-out;
			}
			
			&.exit {
				clip-path: inset(0% 0% 0% 0%);
			}

			&.exit-active {
				clip-path: inset(100% 0% 0% 0%);
				transition: 0.7s clip-path ease-out;
			}

			&.exit-done {
				display: none;
			}
		}

		.poll-transition {
			&.enter {
				opacity: 0;

				&-active {
					opacity: 1;
					transition: 0.7s opacity ease-out;
				}
			}

			&.exit {
				opacity: 1;

				&-active {
					opacity: 0;
					transition: 0.7s opacity ease-out;
				}
			}
		}

		.poll-content-box {
			padding: 15px 25px 15px 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 20px;
			position: relative;

			&.is-loading {
				justify-content: center;
			}

			p {
				margin-bottom: 5px;
			}

			> svg {
				height: 20px;
				color: #fff;
			}

			.button {
				margin-top: 10px;
			}

			.close-btn {
				@extend %reset_button;
				position: absolute;
				top: 0;
				right: 0;
				padding: 5px 15px;
				color: $white;

				svg {
					height: 15px;
				}
			}

			.spinner {
				width: 40px;
				height: 40px;
			}

			.reward {
				color: red;
				text-transform: uppercase;
			}
		}

		.close-content {
			padding: 15px;
			position: relative;

			.close-btn {
				@extend %reset_button;
				position: absolute;
				top: 0;
				right: 0;
				padding: 10px 15px;
				color: $white;
			}

			.title {
				font-size: 22px;
				text-transform: uppercase;
			}
		}

		.poll-head {
			font-size: 16px;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px 20px;
			font-weight: $font-weight-medium;
		}

		.countdown {
			width: 40px;
			height: 40px;
			display: flex;
			position: relative;
			flex-shrink: 0;

			.circle {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: visible;
			}

			.seconds {
				margin: auto;
			}
		}

		.choices-wrapper {
			background-color: rgba($black, 0.25);
		}

		.choice {
			@extend %reset_button;
			display: block;
			font-size: 20px;
			padding: 15px 20px;
			color: $white;
			width: 100%;
			text-align: left;
			transition: 0.3s all ease-out;

			&:hover,
			&:active {
				background-color: $white;
				color: $color-text-secondary;
			}
		}

		&.appear,
		&.enter {
			opacity: 0;
		}

		&.appear-active,
		&.enter-active {
			opacity: 1;
			transition: 0.5s opacity $easeOutCubic;
		}

		&.exit {
			opacity: 1;
		}

		&.exit-active {
			opacity: 0;
			transition: 0.5s opacity $easeOutCubic;
		}
	}

	&.enter,
	&.appear {
		opacity: 0;
		--delay: 0.3s;
	}

	&.enter-active,
	&.appear-active {
		opacity: 1;
		transition: opacity 0.6s $easeOutCubic var(--delay, 0s);
	}
}

.vjs-overlay,
.easelive-container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.video-js {
	// width: 100vw;
	// height: calc(100vw / 16 * 9); // for 16:9 forced video
	width: 100%;
	height: 100%;
	background: transparent !important;
	pointer-events: none;
}

.vjs-control-bar {
	visibility: hidden !important;
}

.vjs-picture-in-picture-control {
	display: none !important;
}
