@keyframes dialog-fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes dialog-fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.dialog {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: $dialog-zindex;
	animation: dialog-fade-out 500ms forwards;

	&-backdrop {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($dialog-backdrop-bgcolor, $dialog-backdrop-opacity);

		@include media-breakpoint-down(sm) {
			background-color: rgba($dialog-backdrop-bgcolor, $dialog-backdrop-opacity-sm);
		}
	}

	&-close {
		position: absolute;
		top: $dialog-close-position-top;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: $dialog-close-size;
		height: $dialog-close-size;
		margin: 0;
		padding: 0;
		font-weight: normal;
		min-width: 0;
		font-size: $dialog-close-fontsize;
		transform: translateY(-100%);

		.icon {
			font-style: normal;
		}
	}

	&-wrapper {
		position: relative;
		width: 80vw;
		max-width: 1000px;
		transform: translate(0, 50%);
		transition: transform $dialog-transition-duration $easeInOutCubic;
		will-change: transform;

		@include media-breakpoint-down(sm) {
			width: 95vw;
		}
	}

	&-content {
		max-height: 80vh;
		overflow-y: auto;
		overflow-x: hidden;
		padding: $dialog-content-padding;
		background-color: $dialog-bgcolor;
		color: $dialog-text-color;
		font-size: $dialog-font-size;
		text-align: left;
	}

	&.is-open {
		animation: dialog-fade-in 500ms forwards;
		
		.dialog-wrapper {
			transform: translate(0, 0);
		}
	}
}

// Lock the scroll on <body>
.body-dialog-open {
	overflow: hidden;
}
