.page {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	padding-left: var(--nav-width);

	background-image: var(--background-image);
	background-size: cover;

	display: flex;

	.page-content {
		padding: 25px 55px;
		flex: 1 1 auto;
		overflow-x: hidden;
		overflow-y: auto;
	}
}