.login {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;

	.page-content {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
	
	.title {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 48px;
		line-height: 1;
		margin-bottom: 15px;
	}

	.splits {
		display: flex;
	}

	.modal {
		width: 1150px;
		height: 600px;
		overflow: hidden;
		padding: 60px;

		background: rgba(23, 24, 26, 0.7);
		backdrop-filter: blur(25px);
		border-radius: 15px;
	}

	.left {
		display: flex;
		gap: 60px;
		height: 400px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 0 90px 0 50px;
		flex: 0 0 auto;
		border-right: 1px solid #626262;
	}

	.right {
		display: flex;
		flex: 1 1 100%;
		justify-content: center;
		padding-top: 20px;
	}

	.image {
		width: 200px;
		height: 200px;
		overflow: hidden;
		border-radius: 9999px;
		margin-bottom: 30px;
	}

	.user-btn {
		cursor: pointer;

		.image {
			filter: drop-shadow(0 0 -1px #{$color-accent-primary});
			transition: filter 0.6s ease;

			img, svg {
				transition: transform 0.6s ease;
			}
		}

		&:hover {
			.image {
				filter: drop-shadow(0 0 10px #{$color-accent-primary});
				img, svg {
					transform: scale(1.1);
				}
			}
		}
	}

	.qr {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}

	.text {
		text-transform: uppercase;
		text-align: center;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 1.3;

		color: #969599;

		strong {
			color: white;
		}
	}

	&.enter,
	&.appear {
		.modal {
			opacity: 0;
			transform: scale(0.8);

			transition: opacity 0.6s $easeOutCubic 1s, transform 0.6s $easeOutCubic 1s;
		}

		.animate {
			opacity: 0;
			transform: translateY(20px);

			--duration: 0.9s;
			--stagger: 0.1s;
			--delay: 1.6s;

			transition: opacity var(--duration) $easeOutCubic calc(var(--i) * var(--stagger) + var(--delay)), transform var(--duration) $easeOutCubic calc(var(--i) * var(--stagger) + var(--delay));
		}
	}

	&.enter-active,
	&.appear-active {
		.modal {
			opacity: 1;
			transform: scale(1);
		}

		.animate {
			opacity: 1;
			transform: translateY(0);
		}
	}
}