.news-card {
	position: relative;
	color: $color-text-primary;
	
	&:hover {
		color: $color-text-primary;
		text-decoration: none;
	}

	svg {
		--color: #{$color-accent-primary};
	}

	.bg {
		img {
			position: relative;
			transform: scale(1);
			transition: transform 0.6s $easeInOutCubic;
		}
	}

	&:hover {
		.bg {
			img {
				transform: scale(1.05);
			}
		}
	}
	
	.news-footer {
		display: flex;
		border-top: 1px solid rgba($white, 0.05);
		margin-top: 10px;
		padding-top: 10px;
		align-items: center;
		gap: 8px;
		font-weight: $font-weight-medium;
		font-size: 11px;

		.icon {
			width: 22px;
		}

		.share {
			margin-right: auto;
		}
	}

	&.large {
		display: grid;
		grid-template: 1fr / 1fr;
		overflow: hidden;
		border-radius: 12px;
		box-shadow: 0 3px 15px rgba($black, 0.16);

		.bg,
		.overlay {
			grid-row: 1;
			grid-column: 1;
			position: relative;
		}

		.bg {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(to bottom, rgba($black, 0), rgba($black, 1) 75%);
			}
		}
	
		.overlay {
			align-self: flex-end;
			line-height: 1.1;
			padding: 16px;
	
			.title {
				font-size: 16px;
				font-weight: $font-weight-medium;
				line-height: 1.3;
				margin-bottom: 3px;
			}
	
			.news-info {
				font-size: 11px;
				opacity: 0.5;
			}
		}
	}

	&.side {
		.bg {
			position: absolute;
			top: 10px;
			left: 0;
			width: 120px;
			height: calc(100% - 20px);
			overflow: hidden;
			border-radius: 12px;
			box-shadow: 0 3px 15px rgba($black, 0.16);

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.overlay {
			background: $black;
			margin-left: 70px;
			overflow: hidden;
			border-radius: 12px;
			padding: 16px 20px 16px 70px;
			box-shadow: 0 3px 15px rgba($black, 0.16);
		}

		.title {
			font-size: 16px;
			font-weight: $font-weight-medium;
			line-height: 1.25;
		}

		.news-info {
			font-size: 12px;
			opacity: 0.55;
		}

		.news-footer {
			margin-top: 7px;
		}
	}

	&.reversed {
		.bg {
			left: auto;
			right: 0;
		}

		.overlay {
			margin-left: 0;
			margin-right: 70px;
			padding: 16px 70px 16px 20px;
		}
	}
}