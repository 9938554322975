.login-rfid-prompt {
	position: fixed;
	bottom: 2.5vw;
	right: 2.5vw;
	z-index: 999;
	font-size: 10vw;
	line-height: 1;
	font-weight: 900;
	text-align: center;

	.text {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;

		&:after {
			content: '';
			position: relative;
			display: block;
			width: 5vw;
			height: 5vw;
			border-left: 3px solid white;
			border-bottom: 3px solid white;
			transform: scale(1, 0.5) rotate(-45deg);
			margin-top: -0.2em;
	
			transform: translate(0, 0);
			animation: login-rfid-prompt 1.2s infinite;
		}
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 150vmax;
		height: 150vmax;
		background: radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 60%);
		transform: translate(50%, 50%);
		z-index: -1;
		pointer-events: none;
	}

	&.enter,
	&.appear {
		opacity: 0;
		transform: translate(0, 2vw);
	}

	&.enter-active,
	&.appear-active {
		opacity: 1;
		transform: translate(0, 0);
		transition: opacity 0.6s $easeOutCubic 0.6s, transform 0.6s $easeOutCubic 0.6s;
	}

	&.exit {
		opacity: 1;
		transform: translate(0, 0);
	}

	&.exit-active {
		opacity: 0;
		transform: translate(0, 2vw);
		transition: opacity 0.6s $easeInCubic, transform 0.6s $easeInCubic;
	}
}

@keyframes login-rfid-prompt {
	0% {
		transform: translate(0, 0) scale(1, 0.5) rotate(-45deg);
	}
	50% {
		transform: translate(0, 1vw) scale(1, 0.5) rotate(-45deg);
	}
	100% {
		transform: translate(0, 0) scale(1, 0.5) rotate(-45deg);
	}
}

.login-in-app-fader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 1);
	opacity: 1;
	pointer-events: none;
	z-index: 99999;

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 200px;
	}

	&.enter,
	&.appear {
		opacity: 0;
	}

	&.enter-active,
	&.appear-active {
		opacity: 1;
		transition: opacity 0.6s $easeInOutCubic;
	}

	&.exit {
		opacity: 1;
	}

	&.exit-active {
		opacity: 0;
		transition: opacity 0.6s $easeInOutCubic;
	}
}

.login-in-app-rfid-prompt {
	position: fixed;
	bottom: 2.5vw;
	right: 2.5vw;
	z-index: 999;
	font-size: 2vw;
	line-height: 1.2;
	font-weight: 900;
	text-align: center;

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 150vmax;
		height: 150vmax;
		background: radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 60%);
		transform: translate(50%, 50%);
		z-index: -1;
		pointer-events: none;
	}

	.choices {
		display: flex;
		justify-content: center;
		gap: 5vw;
		margin-top: 1vw;
	}

	&.enter,
	&.appear {
		opacity: 0;
		transform: translate(0, 2vw);
	}

	&.enter-active,
	&.appear-active {
		opacity: 1;
		transform: translate(0, 0);
		transition: opacity 0.6s $easeOutCubic, transform 0.6s $easeOutCubic;
	}

	&.exit {
		opacity: 1;
		transform: translate(0, 0);
	}

	&.exit-active {
		opacity: 0;
		transform: translate(0, 2vw);
		transition: opacity 0.3s $easeInOutCubic, transform 0.3s $easeInOutCubic;
	}
}