.lobby {
	position: relative;
	display: grid;
	height: 100vh;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr;
	align-content: stretch;
	justify-content: center;
	text-align: center;
	gap: 1px;
	background: #111;

	.lobby-item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		background-position: center center;
		z-index: 0;

		.overlay {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 1;

			--blur-amount: 5px;

			background: var(--accent);
			-webkit-backdrop-filter: blur(var(--blur-amount));
			-moz-backdrop-filter: blur(var(--blur-amount));
			backdrop-filter: blur(var(--blur-amount));
		}

		a {
			position: relative;
			z-index: 2;
			color: $white;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: $font-weight-black;
			font-size: 32px;
			line-height: 1.1;
			letter-spacing: 0.05em;
			text-shadow: 0 2px 4px rgba(0,0,0,0.5);
			padding: 100px;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				display: block;
				margin-bottom: -30px;
			}
		}
	}
}