.profile {

	.page-content {
		padding: 0 120px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		h2 {
			font-weight: 500;
			font-size: 24px;
			line-height: 1;
		}

		h1 {
			font-weight: 800;
			font-size: 74px;
			line-height: 1;
			text-transform: uppercase;
			margin-bottom: 0.6em;
		}

		.content {
			&.appear,
			&.enter {
				.animated {
					opacity: 0;
					transform: translate(0, 20px);
				}

				&-active {
					.animated {
						opacity: 1;
						transform: translate(0, 0);
						transition: opacity 0.6s $easeOutCubic calc(0.1s * var(--i, 0s)), transform 0.6s $easeOutCubic calc(0.1s * var(--i, 0s));
					}
				}
			}
		}

	}

	.reward-track-table-container {
		margin: 0;
		height: 265px;

		padding-right: 165px;
	}

	.reward-track {
		--foreground-color: var(--team-main-color);
	}

	.reward-track-name {
		background-color: var(--team-main-color);
		font-size: 0;
	}

	.stat-bar {
		&-inner {
			background-color: var(--team-secondary-color);
		}
	}

	.sidebar {
		--width: 615px;

		> .lined-title:first-child {
			&:after {
				display: none;
			}
		}
	}

	.logout-button {
		position: absolute;
		top: 40px;
		right: 30px;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		line-height: 34px;
		cursor: pointer;
	}

	.rounded {
		border: 2px solid rgba(#707070, 0.4);
		border-radius: 20px;
		background: mix($white, $black, 10%);
		padding: 15px 20px;
		box-shadow: 2px 2px 10px rgba($black, 0.6);

		&.mission {
			padding: 15px 10px;
		}
	}

	.items-box {
		margin-bottom: 15px;
	}

	.items-box,
	.stats-panel {
		.lined-title {
			&:after {
				display: none;
			}
		}
	}

	.player-profile {
		display: flex;
		margin: 15px 0;
	}

	.player-card {
		position: relative;
		flex: 1 1 100%;
		display: flex;
		align-items: center;
		padding-left: 25px;

		.content {
			display: flex;
			align-items: center;
		}

		.chevron-icon {
			position: absolute;
			top: 50%;
			right: 35px;
			width: 8px;
			transform: translate(0, -50%);
		}

		.avatar {
			--avatar-width: 75px;
			margin-right: 20px;

			.circle-progress {
				--size: 100px;
			}
		}

		.player-name,
		.xp,
		.role {
			font-size: 17px;
			font-weight: $font-weight-medium;
			line-height: 20px;
		}

		.player-name {
			display: flex;

			.level-badge {
				margin-left: 6px;
			}
		}

		.xp {
			margin-top: 4px;
			font-weight: $font-weight-bold;

			color: rgba($white, 0.5);

			.current {
				color: $color-accent-primary;
			}
		}

		.role {
			margin-top: 4px;
			color: $color-accent-primary;
		}
	}

	.wallet {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-left: -25px;
		padding: 45px 45px 45px 75px;
		background-color: mix($white, $black, 15%);

		.amount {
			font-size: 24px;
			font-weight: $font-weight-semibold;
			line-height: 29px;
		}

		.currency {
			font-size: 17px;
			font-weight: $font-weight-semibold;
			line-height: 20px;
		}
	}

	.no-brand {
		line-height: 1.2;
		margin-top: 25px;
		font-size: 12px;
	}

	.items-list {
		display: grid;
		grid-template-columns: repeat(auto-fit, 105px);
		row-gap: 15px;
		justify-content: space-evenly;
		margin-top: 15px;
		overflow: hidden;

		&.brands {
			--items-base-opacity: 0.5;
		}

		.item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			opacity: var(--items-base-opacity, 1);
			transition: opacity 0.3s ease;
			cursor: pointer;

			--team-inactive-opacity: 0.75;

			&:hover:not(.favorite) {
				opacity: var(--team-inactive-opacity);
			}

			&.favorite {
				opacity: 1;
				pointer-events: none;
			}

			img {
				max-width: 105px;
				max-height: 105px;
			}

			&.brand-1 img {
				margin-top: -10px;
			}

			&.brand-2 img {
				max-height: 60px;
			}

			.add-product-img {
				width: 75px;
				height: 75px;
				border-radius: 50%;
				display: flex;
				background-color: #524C4C;

				svg {
					margin: auto;
				}
			}

			.item-name {
				font-size: 14px;
				text-align: center;
				margin-top: auto;
			}

			&.enter {
				opacity: 0;
				translate: 0 30px;
			}

			&.enter-active {
				opacity: 1;
				translate: 0 0;
				transition: 0.5s opacity ease-out var(--product-delay, 0s),
					0.5s translate ease-out var(--product-delay, 0s);
			}

			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					--product-delay: #{$i * 0.1s};
				}
			}
		}

		&.products {
			line-height: 1.2;

			img {
				object-fit: contain;
				object-position: center;
				aspect-ratio: 1;
			}
		}
	}

	.missions-title,
	.achievements-title {
		margin: 30px 0 15px;
	}

	.badges {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 25px;
	}

	.badge {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		text-align: center;
		padding-left: 20px;
		padding-right: 20px;

		--badge-inactive-opacity: 0.3;

		opacity: var(--badge-inactive-opacity);
		transition: opacity 0.3s ease;

		.name {
			font-size: 16px;
			font-weight: $font-weight-bold;
			line-height: 19px;
			padding-bottom: 0.5em;
			margin: 0.5em 0 auto;
		}

		.rarity {
			font-size: 11px;
			text-transform: uppercase;
			line-height: 13px;
			opacity: 0.4;
		}

		svg, img {
			width: 65px;
			margin-bottom: auto;

			transition: filter 0.3s ease;
			filter: saturate(0);
		}

		&.unlocked {
			opacity: 1;

			svg, img {
				filter: saturate(1);
			}
		}
		
		&:hover:not(.unlocked) {
			opacity: 0.8;

			svg, img {
				filter: saturate(1);
			}
		}
	}

	.stats-panel {
		margin: 0 0 30px;

		.stats-grid {
			margin-top: 15px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: 10px;

			.stat {
				.name {
					font-size: 14px;
					line-height: 16px;
					font-weight: $font-weight-medium;
					text-transform: uppercase;
					color: rgba($white, 0.5);
					
					strong {
						color: rgba($white, 1);
					}
				}

				.value {
					color: $color-accent-primary;
					font-weight: $font-weight-bold;
					font-size: 18px;
				}
			}
		}
	}

	.poll-ctn {
		max-width: 720px;
		width: 100%;

		.above-poll-title {
			margin-bottom: 30px;
			text-shadow: 0 0 10px rgba($black, 0.5);
			text-align: center;
		}

		.poll {
			grid-row: 1;
			grid-column: 1;

			display: inline-flex;
			flex-direction: column;
			
			background: rgba(18, 18, 18);
			border-radius: 10px;
			padding: 90px;

			.question {
				flex: 0 0 auto;
				margin-bottom: 70px;
				text-align: center;
			}

			.ctn {
				display: grid;
				align-items: center;
				justify-items: center;

				> * {
					grid-row: 1;
					grid-column: 1;
				}
			}

			.spinner, .answer, .ctn {
				flex: 1 1 100%;
			}

			.spinner {
				width: 120px;
				margin: 0 auto;
			}

			&.answered {
				pointer-events: none;
			}

			.question {
				font-size: 24px;
				font-weight: bold;
				margin-bottom: 1em;
			}

			.choices {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 20px;
			}

			.choice {
				text-align: center;
				padding: 10px 20px;
				border: 1px solid white;
				border-radius: 10px;
				cursor: pointer;
				min-width: 200px;

				background: rgba(0, 0, 0, 0.1);
				transition: background-color 0.6s ease, color 0.6s ease;

				&.selected,
				&:hover {
					background: $color-accent-primary;
					border-color: $color-accent-primary;
				}
			}

			.choices,
			.spinner,
			.answer {
				opacity: 0;
				transform: scale(0.5);
				pointer-events: none;
	
				&.appear,
				&.enter {
					opacity: 0;
	
					&-active {
						opacity: 1;
						transform: scale(1);
						transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
					}
	
					&-done {
						opacity: 1;
						transform: scale(1);
						pointer-events: all;
					}
				}
			}
		}

		.poll.no-poll {
			flex-direction: row;
			gap: 20px;
		}

		&.appear,
		&.enter {
			opacity: 0;
			transform: translate(0, 30px);

			&-active {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic, transform 0.6s $easeOutCubic;
			}
		}

		&.exit {
			opacity: 1;

			&-active {
				opacity: 0;
				transition: opacity 0.6s $easeOutCubic;
			}
		}
	}

	&.enter,
	&.appear {
		.player-profile,
		.items-box {
			opacity: 0;
			transform: translate(0, 40px);
		}

		.items-box {
			--delay: 0.1s;
		}
		
		.badge,
		.team {
			opacity: 0;
			transform: translate(0, 40px);
		}

		@for $i from 1 to 10 {
			.team:nth-child(#{$i}) {
				--delay: #{$i * 0.05s + 0.2s};
			}

			.badge:nth-child(#{$i}) {
				--delay: #{$i * 0.05s + 0.3s};
			}
		}
	}

	&.enter-active,
	&.appear-active {
		.player-profile,
		.items-box {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}
		
		.badge {
			opacity: 1;

			&:not(.unlocked) {
				opacity: var(--badge-inactive-opacity);
			}

			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}

		.team {
			opacity: 1;

			&:not(.favorite) {
				opacity: var(--team-inactive-opacity);
			}

			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}
	}
}