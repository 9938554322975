.sidebar {
	--width: 380px;
	--padding: 40px 30px;
	--blur-amount: 10px;

	position: relative;
	width: var(--width);
	height: calc(var(--vh, 1vh) * 100);
	padding: var(--padding);
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	flex: 0 0 auto;

	background: rgba($color-background-main, 0.7);
	-webkit-backdrop-filter: blur(var(--blur-amount));
	-moz-backdrop-filter: blur(var(--blur-amount));
	backdrop-filter: blur(var(--blur-amount));
	border-left: 1px solid rgba($white, 0.25);
}



.page.enter,
.page.appear {
	.sidebar {
		opacity: 0;
		transform: translate(100%, 0);
	}
}

.page.enter-active,
.page.appear-active {
	.sidebar {
		opacity: 1;
		transform: translate(0, 0);
		transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
	}
}