
.match-prediction-page {
	z-index: 9999;
	//background: url('https://xrdatalakenab2022.blob.core.windows.net/react/uddq8e/assets/homepage_bg.jpg');
	//background-size: cover;
	padding-left: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	transform-origin: center center;
	
	&:after {
		position: absolute;
			top: 0;
			left: 0;

		width: 100%;
		height: 100%;
		content: '';
		background: rgba($color-background-main, 0.9);
	}

	.spinner {
		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%, -50%);
		width: 200px;
		z-index: 2;
	}

	&.appear,
	&.enter {
		opacity: 0;
		//transform: scale(0.8);

		&-active,
		&-done {
			transition: opacity 0.6s $easeOutCubic, transform 0.6s $easeOutCubic;
			//transform: scale(1);
			opacity: 1;
		}
	}
}


.match-prediction {
	position: relative;
	z-index: 2;
	font-family: $font-family-title;
	border-radius: 30px;
	text-align: center;

	.title {
		font-size: 28px;
		font-weight: bold;
		text-transform: uppercase;
		color: $color-accent-primary;
	}

	.question {
		color: #fff;
		font-weight: bold;
		font-size: 40px;
		margin-bottom: 10px;
	}

	.choices {
		display: flex;
		gap: 10px;
	}

	.choice {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px 25px;
		text-align: center;
		font-size: 20px;
		text-transform: uppercase;
		font-weight: bold;
		width: 100%;
		margin-bottom: 10px;
		cursor: pointer;
		
		min-width: 200px;
		min-height: 100px;
		border: 2px solid $color-accent-primary;
		border-radius: 20px;
		transition: background-color 0.3s;

		&:hover {
			background-color: rgba(#C9082A, 0.5);
		}
		
		&.active {
			background: $color-accent-primary;
		}
	}

	.button {
		margin-top: 40px;
	}

	&.appear,
	&.enter {
		.title, .question, .choice, .button {
			opacity: 0;
			transform: translate(0, 40px);
		}

		.question {
			--delay: 0.1s;
		}

		@for $i from 1 to 20 {
			.choice:nth-child(#{$i}) {
				--delay:#{($i) * 0.1s + 0.2s};
			}
		}

		.button {
			--delay: 0.4s;
		}
	
		&-active,
		&-done {
			.title, .question, .choice, .button {
				opacity: 1;
				transform: translate(0, 0px);
				transition: transform 0.6s $easeOutCubic var(--delay, 0s), opacity 0.6s $easeOutCubic var(--delay, 0s);
			}
		}
	}
	
	&.exit {
		.title, .question, .choice, .button {
			opacity: 1;
			transform: translate(0, 0);
		}
	
		&-active,
		&-done {
			.title, .question, .choice, .button {
				opacity: 0;
				transform: translate(0, -40px);
				transition: transform 0.3s $easeOutCubic var(--delay, 0s), opacity 0.3s $easeOutCubic var(--delay, 0s);
			}
		}
	}
}


.prediction-resolved {
	position: relative;
	z-index: 2;
	font-family: $font-family-title;
	border-radius: 30px;
	text-align: center;

	.title {
		font-size: 28px;
		font-weight: bold;
		text-transform: uppercase;
		color: $color-accent-primary;
	}

	.subtitle {
		font-size: 24px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.question {
		color: #fff;
		font-weight: bold;
		font-size: 40px;
		margin-bottom: 10px;
	}

	.answers {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;;
	}

	.answer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px 25px;
		text-align: center;
		font-size: 20px;
		text-transform: uppercase;
		font-weight: bold;
		width: 100%;
		margin-bottom: 30px;
		cursor: pointer;
		width: auto;
		
		min-width: 200px;
		min-height: 100px;
		border: 2px solid $color-accent-primary;
		border-radius: 20px;
		transition: background-color 0.3s;
		background: rgba(#C9082A, 0.5);
		
		&.correct {
			background: rgba(#1cb91c, 0.5);
			border: 2px solid #1cb91c;
		}
	}

	.button {
		margin-top: 40px;
	}

	&.appear,
	&.enter {
		.title, .question, .subtitle, .answer, .button {
			opacity: 0;
			transform: translate(0, 40px);
		}

		.question {
			--delay: 0.1s;
		}

		@for $i from 1 to 20 {
			.subtitle:nth-child(#{$i}),
			.answer:nth-child(#{$i}) {
				--delay:#{($i) * 0.075s + 0.2s};
			}
		}

		.button {
			--delay: 0.6s;
		}
	
		&-active,
		&-done {
			.title, .question, .subtitle, .answer, .button {
				opacity: 1;
				transform: translate(0, 0px);
				transition: transform 0.6s $easeOutCubic var(--delay, 0s), opacity 0.6s $easeOutCubic var(--delay, 0s);
			}
		}
	}
	
	&.exit {
		.title, .question, .subtitle, .answer, .button {
			opacity: 1;
			transform: translate(0, 0);
		}
	
		&-active,
		&-done {
			.title, .question, .subtitle, .answer, .button {
				opacity: 0;
				transform: translate(0, -40px);
				transition: transform 0.3s $easeOutCubic var(--delay, 0s), opacity 0.3s $easeOutCubic var(--delay, 0s);
			}
		}
	}
}