.ad-space {
	position: relative;
	display: block;
	overflow: hidden;
	flex: 0 0 auto;
	background: black;
	pointer-events: none;

	&.big-box {
		width: 300px;
		height: 250px;
	}

	iframe, video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}

	&:before {
		content: 'Ad';
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 4px 6px;
		line-height: 1;
		background: white;
		color: black;
		font-size: 10px;
		border-top-left-radius: 6px;
		opacity: 0.5;
		z-index: 1;
	}
}

.rewarded-ad {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 8888;
	display: flex;
	align-items: center;
	justify-content: center;

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.75);
	}

	&-panel {
		background: $color-background-main;
		border: 2px solid #5C5C5C;

		overflow: hidden;

		text-align: center;

		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		align-items: center;
		justify-content: center;

		.watch-to-win,
		.ad-space,
		.ad-ended {
			grid-column: 1;
			grid-row: 1;
			opacity: 0;
			pointer-events: none;
			position: relative;
			z-index: 2;
		}

		.ad-space,
		.ad-ended {
			padding: 50px;
		}

		[class^=button] {
			font-size: 22px;
			padding: 0.25em 0.5em;
		}

		.watch-to-win {
			display: flex;
			width: 1000px;
			height: 550px;

			h1, p, .reward, .btns {
				opacity: 0;
				transform: translate(0, 20px);
				transition: opacity 0.6s $easeOutCubic calc(var(--i) * 0.1s), transform 0.6s $easeOutCubic calc(var(--i) * 0.1s);
			}

			.message {
				max-width: 20ch;
				text-align: center;
				font-size: 22px;
				line-height: 1.5;
			}

			.content {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 50px;
			}

			.side-img {
				display: block;
				object-fit: cover;
				object-position: center;
				height: 100%;
				width: 55%;
			}

			.btns {
				margin-top: 40px;
				align-items: flex-start;
			}

			.watch-btn-wrapper p {
				margin-top: 15px;
				text-align: center;
				line-height: 1.3;
			}
		}

		&.intro {
			.watch-to-win {
				opacity: 1;
				pointer-events: all;
				
				h1, p, .reward, .btns {
					opacity: 1;
					transform: translate(0, 0);
				}
			}
		}
		
		.ad-space {
			transition: opacity 0.6s $easeInOutCubic;
		}

		&.ad-playing {
			.ad-space {
				opacity: 1;
				pointer-events: all;
			}
		}

		.ad-ended {
			h1, p, .reward, .btns {
				opacity: 0;
				transform: translate(0, 20px);
				transition: opacity 0.6s $easeOutCubic calc(var(--i) * 0.1s + 0.6s), transform 0.6s $easeOutCubic calc(var(--i) * 0.1s + 0.6s);
			}
		}

		&.ad-ended {
			.ad-ended {
				opacity: 1;
				pointer-events: all;
				
				h1, p, .reward, .btns {
					opacity: 1;
					transform: translate(0, 0);
				}
			}
		}

		.ad-space {
			position: relative;
			width: 100%;
			height: 100%;

			.countdown {
				position: absolute;
				top: 10px;
				right: 10px;

				width: 30px;
				height: 30px;
				border-radius: 9999px;
				overflow: hidden;
				border: 2px solid $white;
				color: $white;
				filter: drop-shadow(0 0 5px black);

				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 1;
				font-size: 20px;
				z-index: 2;
			}
		}

		.rewards {
			display: flex;

			justify-content: center;
			gap: 20px;
			margin: 20px 0 30px;

			.reward {
				width: 100px;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
				font-weight: 900;
				text-transform: uppercase;
				background:rgba(0, 0, 0, 0.5);

				.currency {
					color: #fcba00;
				}

				.stat {
					color: $color-accent-primary;
				}
			}
		}

		.close {
			margin-top: 20px;

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}

		.btns {
			display: flex;
			gap: 20px;
			justify-content: center;
			align-items: center;
			margin-top: 20px;

			.watch {
				background: $color-accent-primary;
				color: $white;
			}

			[class^=button] {
				margin-bottom: 0;
			}

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	&.appear,
	&.enter {
		.background {
			opacity: 0;
			transition: opacity 0.6s $easeOutCubic;
		}

		.rewarded-ad-panel {
			opacity: 0;
			transform: scale(0.9);
			transition: opacity 0.6s $easeOutCubic 0.3s, transform 0.6s $easeOutCubic 0.3s;
		}
	}

	&.appear-active,
	&.enter-active {
		.background {
			opacity: 1;
		}

		.rewarded-ad-panel {
			opacity: 1;
			transform: scale(1);
		}
	}

	&.exit {
		.background {
			opacity: 1;
			transition: opacity 0.6s $easeOutCubic 0.3s;
		}

		.rewarded-ad-panel {
			opacity: 1;
			transform: scale(1);
			transition: opacity 0.6s $easeOutCubic, transform 0.6s $easeOutCubic;
		}
	}

	&.exit-active {
		.background {
			opacity: 0;
		}

		.rewarded-ad-panel {
			opacity: 0;
			transform: scale(0.9);
		}
	}
}