.rebates {
	width: 450px;
	.bracket {
		width: 28px;
		height: 12px;
		margin: auto;
		&.left {
			border-left: 1px solid #202020;
			border-top: 1px solid #202020;
		}
		&.right {
			padding-right: 3px;
			border-right: 1px solid #202020;
			border-top: 1px solid #202020;
		}
	}
	.rebate {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 30px;
		line-height: 121.02%;
		/* or 36px */

		text-align: center;

		color: #202020;
		&.active {
			color: #ffffff;
		}
		.text {
			font-size: 12px;
		}
	}
}
