.notification {
	--width: 360px;
	--height: 60px;

	position: fixed;
	bottom: 30px;
	left: calc(50%);
	transform: translate(calc(var(--width) / -2), 0);
	cursor: pointer;

	z-index: 1000;

	.notification-icon {
		position: absolute;

		width: var(--height);
		height: var(--height);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 9999px;
		background: mix($black, $white, 90%);
		box-shadow: 0 0 10px rgba(0,0,0,0.5);
		overflow: hidden;

		font-size: 28px;
		color: $color-accent-primary;
		z-index: 1;
	}

	.glow {
		--blur-amount: 15px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: var(--width);
		height: var(--height);
		border-radius: 9999px;

		animation: glow-loop 2s ease-in-out infinite;
		animation-delay: 1.9s;
	}

	@keyframes glow-loop {
		0% {
			box-shadow: 0 0 var(--blur-amount) rgba($color-accent-primary-rgb, 0);
		}
		50% {
			box-shadow: 0 0 var(--blur-amount) rgba($color-accent-primary-rgb, 1);
		}
		100% {
			box-shadow: 0 0 var(--blur-amount) rgba($color-accent-primary-rgb, 0);
		}
	}

	.notification-content {
		position: relative;
		text-align: center;
		width: var(--width);
		height: var(--height);
		background: $black;
		box-shadow: 0 0 10px rgba(0,0,0,0.5);

		border-radius: calc(var(--height) / 2);
	}

	.notification-text {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		width: var(--width);
		padding: 10px 10px 10px calc(var(--height) + 10px);
		font-size: 14px;

		line-height: 1.3;

		.title {
			font-weight: $font-weight-bold;
			margin-bottom: 0.25em;
		}
	}

	&.enter,
	&.appear {
		transform: translate(0, 0);

		.notification-icon {
			svg {
				position: relative;
				transform: translate(var(--height), 0) rotate(90deg);
			}
		}

		.translate {
			transform: translate(0, var(--height));
		}

		.scale {
			transform: scale(0.2);
		}

		.notification-content {
			width: var(--height);
			opacity: 0;
			padding-left: 0;
			padding-right: 0;
			transform: translate(calc(var(--height) / 2), 0);
			overflow: hidden;
			background: $color-accent-primary;
		}

		.notification-text {
			.text, .title {
				opacity: 0;
			}
		}
	}

	&.enter-active,
	&.appear-active {
		--scale-up-duration: 0.4s;
		--translate-up-duration: 0.3s;
		--move-x-delay: 0.7s;
		--move-x-duration: 0.9s;
		--move-x-ease: #{$easeSabelle};
		--icon-roll-delay: 0.4s;
		--icon-roll-duration: 0.6s;

		transform: translate(calc(var(--width) / -2), 0);
		transition: transform var(--move-x-duration) var(--move-x-ease) var(--move-x-delay);

		.translate {
			transform: translate(0, 0);
			transition: transform var(--translate-up-duration) $easeOutCubic;
		}

		.scale {
			transform: scale(1);
			transition: transform var(--scale-up-duration) $easeOutBack;
		}

		.notification-icon {
			svg {
				position: relative;
				transform: translate(0, 0) rotate(0deg);
				transition: transform var(--icon-roll-duration) $easeOutCubic var(--icon-roll-delay);
			}
		}

		.notification-content {
			width: var(--width);
			transform: translate(0, 0);
			opacity: 1;
			background: $black;
			transition: opacity 0s linear var(--move-x-delay),
						width var(--move-x-duration) var(--move-x-ease) var(--move-x-delay),
						background-color var(--move-x-duration) $easeOutCubic var(--move-x-delay);
		}

		.notification-text {
			.title, .text {
				opacity: 1;
				transition: opacity 0.6s $easeOutCirc calc(var(--move-x-delay) + (var(--move-x-duration) * 0.8));
			}
		}
	}

	&.exit {
		transform: translate(calc(var(--width) / -2), 0);
		opacity: 1;
	}

	&.exit-active {
		transform: translate(calc(var(--width) / -2), 25%);
		opacity: 0;
		transition: transform 0.3s $easeInSine, opacity 0.3s $easeInSine;
	}
}