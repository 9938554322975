.page-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		opacity: 0;
		transition: opacity 0.3s ease;
	
		&.current {
			opacity: 1;
		}
	}
}