.reward-track-page {
	.page-content {
		padding: 0 0 0 112px;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 80px;
	
		.xp-bar {
			margin-bottom: 30px;
			margin-right: 165px;

			.right {
				filter: drop-shadow(0 0 5px black)
			}
		}

		h2 {
			font-weight: 500;
			font-size: 24px;
			line-height: 1;
		}

		h1 {
			font-weight: 800;
			font-size: 74px;
			line-height: 1;
			text-transform: uppercase;
			margin-bottom: 60px;
		}

		.reward-track-wrapper {
			flex-shrink: 1 1 auto;
			min-width: 0;
		}

		.challenges-wrapper {
			width: 540px;
			flex-shrink: 0;
			border: 1px solid rgba($white, 0.2);
			border-right: none;
			padding: 22px;
			background: mix($white, $black, 10%);
			align-self: flex-start;
			translate: 0 var(--challenges-wrapper-top);

			.missions-title {
				margin-bottom: 15px;
			}
		}
	}

	&.enter {
		.content,
		.challenges-wrapper {
			opacity: 0;
		}

		.challenges-wrapper {
			--transition-delay: 0.3s;
		}
	}

	&.enter-active {
		.content,
		.challenges-wrapper {
			opacity: 1;
			transition: 0.7s opacity $easeOutCubic var(--transition-delay, 0s);
		}
	}
}

.reward-track-table-container {
	position: relative;

	.scroll-btn {
		@extend %reset_button;
		display: flex;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		translate: 0 -50%;
		border: 1px solid rgba($white, 0.7);

		svg {
			height: 50%;
			margin: auto;
			color: rgba($white, 0.7);
		}

		&.prev {
			right: calc(100% + 15px);
		}

		&.next {
			left: calc(100% + 15px);
		}
	}
}

.reward-track-page {
	.season-pass-xp {
		margin-bottom: 20px;
		display: flex;
		gap: 10px;
		justify-content: flex-start;
	}

	.season-pass-table-container {
		width: calc(100% + 50px);
		overflow-x: scroll;
		margin-right: -50px;
		padding-right: 50px;
	}
}

.table-scroller {
	width: 100%;
	min-width: 0;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

.reward-tracks {
	border-collapse: collapse;
	border-spacing: 0px;
	height: auto;
}

.reward-track {
	--spacing: 4px;
	--tier-item-width: 190px;
	--tier-item-height: 240px;
	--none-background-color: #090b0d;
	--color-accent: #{var(--color-accent-primary)};
	border: 0;

	.stat-bar {
		border-radius: 0;
		border: none;
		margin-top: var(--spacing);
		width: calc(100% - var(--spacing));
		position: relative;
		left: var(--spacing);

		--foreground-color: var(--color-accent);
		--background-color: #13181b;

		justify-content: center;

		.stat-bar-current-level {
			display: none;
		}
	}

	.reward-tier.first {
		.stat-bar {
			left: 0;
			width: 100%;
		}
	}

	&[class*=free] {
		--color-accent: var(--color-accent-primary);

		&:not(.is-active) .reward-track-name {
			background: #13181b;
		}
	}

	&[class*=free] {
		.reward-track-name .active {
			display: none;
		}
	}

	&.has-tracker + .reward-track {
		.reward-tier {
			padding-top: var(--spacing);
		}
	}

	&.appear,
	&.enter {
		opacity: 0;

		&-active {
			opacity: 1;
			transition: opacity 0.3s $easeOutCubic;
		}
	}
}

.reward-track-name {
	position: sticky;
	left: 0;
	padding: 30px 50px 30px 30px;
	font-size: 24px;
	line-height: 1.083;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	background: var(--color-accent-primary);
	width: calc(210px + var(--spacing));
	z-index: 5;
	border-bottom-left-radius: 10px;
	border-top-left-radius: 10px;

	&:before {
		transition: opacity 0.2s ease;
		opacity: 0;
		content: '';
		position: absolute;
		top: 0;
		left: 100%;
		width: 50%;
		height: 100%;
		background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0));
	}

	&.scrolled:before {
		opacity: 1;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-right: var(--spacing) solid black;
	}

	.active {
		color: #f9c549;
		font-size: 20px;
		line-height: 25px;
		margin-top: 0.2em;
		display: none;
	}

	.track-description {
		display: inline-block;
		margin-top: 10px;
		font-size: 16px;
		text-transform: none;
	}
}

.reward-tier {
	padding: 0;
	position: relative;

	&.first {
		.reward-tier-item:first-child {
			margin-left: 0;
		}
	}

	&-items {
		position: relative;
		display: flex;
		gap: var(--spacing);
		width: var(--tier-item-width);
		height: var(--tier-item-height);

		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			display: block;
			top: 0;
			left: calc(var(--tier-item-width) + var(--spacing));
			width: calc(100% - var(--tier-item-width));
			height: 100%;

			background: linear-gradient(to right, var(--none-background-color) 0px, var(--none-background-color) var(--tier-item-width), transparent var(--tier-item-width));
			background-size: calc(var(--tier-item-width) + var(--spacing)) 100%;
		}
	}

	&-item {
		position: relative;
		width: var(--tier-item-width);
		height: var(--tier-item-height); 
 
		display: flex;
		text-align: center;
		align-items: flex-end;
		justify-content: center;
		background: #090b0d;

		font-size: 16px;
		line-height: 1.2;
		padding: 12px 32px;
		font-weight: bold;
		text-transform: uppercase;

		z-index: 1;

		.currency-reward-icon,
		img {
			position: absolute;
			top: 3rem;
			left: 50%;
			translate: -50% 0;
			width: 50%;
			user-select: none;
			pointer-events: none;
		}

		.name {
			position: relative;
			z-index: 2;
		}

		.count {
			position: absolute;
			bottom: 0;
			right: 0;
			color: white;
			background: #4e4e4e;
			z-index: 2;
			font-size: 16px;
			padding: 6px 6px 4px;
			line-height: 1;
		}

		.item-claimable {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			border: 4px solid var(--color-accent-primary);

			display: none;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				box-shadow: inset 0px 0px 43px 0px var(--color-accent-primary);
				opacity: 0.35;
			}
		}

		.item-claimed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			display: none;
			align-items: center;
			justify-content: center;

			svg {
				width: 60px;
				height: 55px;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: var(--color-accent-primary);
				opacity: 0.35;
			}
		}

		.item-locked {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			display: none;
			align-items: center;
			justify-content: center;

			svg {
				width: 33px;
				height: 45px;
				z-index: 2;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #000;
				opacity: 0.4;
			}
		}
	
		&:first-child {
			margin-left: var(--spacing);
		}

		&.locked {
			.name {
				opacity: 0.6;
			}

			.item-locked {
				display: flex;
			}
		}

		&.claimed {
			.name {
				opacity: 0.6;
			}

			.item-claimed {
				display: flex;
			}
		}

		&.not-claimed {
			.name {
				opacity: 0.6;
			}

			.item-not-claimed {
				display: flex;
			}
		}
	}
}