.profile-completion {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;

	display: none;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.inner {
		--blur-amount: 10px;
		position: relative;
		z-index: 1;
		padding: 30px 50px;
		border-radius: 10px;
		overflow: hidden;

		background: rgba($color-background-main, 0.7);
		-webkit-backdrop-filter: blur(var(--blur-amount));
		-moz-backdrop-filter: blur(var(--blur-amount));
		backdrop-filter: blur(var(--blur-amount));
	}

	.header {
		font-size: 2rem;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		line-height: 1;
		margin-bottom: 0.5em;
	}

	.progress {
		width: 320px;
		margin: 0 auto 20px;
	}

	.fields {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 20px;
	}

	.field {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}