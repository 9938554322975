.challenges {
	.page-title {
		font-size: 50px;
		font-weight: $font-weight-black;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 1em;
	}

	.sub-title {
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 100%;
		color: $white;
		margin-bottom: 6px;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		--blur-amount: 15px;
		background: rgba($color-background-main, 0.8);
		-webkit-backdrop-filter: blur(var(--blur-amount));
		-moz-backdrop-filter: blur(var(--blur-amount));
		backdrop-filter: blur(var(--blur-amount));
	}

	.page-content {
		position: relative;
		z-index: 2;
	}

	.challenges-ctn {
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
	}

	&.appear,
	&.enter {
		.page-title,
		.challenge-card {
			opacity: 0;
			transform: translate(0, 20px);
		}

		@for $i from 1 to 20 {
			.challenge-card:nth-child(#{$i}) {
				--delay:#{($i) * 0.1s};
			}
		}
	}

	&.appear-active,
	&.enter-active {
		.page-title,
		.challenge-card {
			--calc-delay: calc(var(--delay, 0s) + 0.5s);
			opacity: 1;
			transform: translate(0, 0px);
			transition: transform 0.6s $easeOutCubic var(--calc-delay), opacity 0.6s $easeOutCubic var(--calc-delay);
		}
	}
}

.mini-games {
	.challenge-card {
		width: 460px * 0.9;
		height: 720px * 0.9;

		.title {
			position: absolute;
			top: 40px;
			left: 35px;
			font-size: 48px;
			text-align: left;
		}
	}

	.leaderboard {
		max-width: 550px;
		height: auto;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		top: -100px;
	}
}

.challenge-card {
	position: relative;
	z-index: 1;

	width: 460px;
	height: 720px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 30px 60px;
	text-align: center;
	border-radius: 26px;
	overflow: hidden;
	cursor: pointer;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

	&, &:hover {
		color: white;
		text-decoration: none;
	}

	&.completed {
		outline: 2px solid $color-accent-primary;
	}

	.title {
		font-size: 28px;
		font-weight: $font-weight-black;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 0.35em;
		transform: translate(0, 0);
		transition: transform 0.6s ease;
	}

	.button {
		width: 160px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 12px;
	}

	.description {
		line-height: 1.3;
		transform: translate(0, 0);
		transition: transform 0.6s ease;
	}

	.time-left {
		position: absolute;
			top: 20px;
			right: 20px;
		padding: 14px 27px;
		font-size: 18px;
		border-radius: 27px;
		font-weight: bold;

		--blur-amount: 10px;
		background: rgba($color-background-main, 0.3);
		-webkit-backdrop-filter: blur(var(--blur-amount));
		-moz-backdrop-filter: blur(var(--blur-amount));
		backdrop-filter: blur(var(--blur-amount));

		svg {
			margin-right: 14px;
		}

		&.closed {
			color: $color-accent-primary;
		}
	}

	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgba($black, 0) 50%, rgba($black, 0.8) 100%);
	}

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
		transform: scale(1);
		transition: transform 0.6s ease;

		img {
			position: absolute;
				top: 0;
				left: 0;

			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&:hover {
		.bg {
			transform: scale(1.1);
		}

		.title,
		.description {
			transform: translate(0, -20px);
		}
	}
}

.single-challenge {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:rgba($black, 0.5);
		z-index: 1;
	}

	.page-content {
		z-index: 2;
	}

	.back-button {
		display: inline-block;
		text-decoration: none;
		color: $color-text-primary;
		font-size: 14px;
		margin-bottom: 1em;
	}

	.page-title {
		font-size: 36px;
		font-weight: $font-weight-black;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 1em;
		text-align: center;
	}

	.question-ctn {
		max-width: 640px;
		margin: 0 auto;
		text-align: center;
	}

	&.appear,
	&.enter {
		opacity: 0;
	}

	&.appear-active,
	&.enter-active {
		opacity: 1;
		transition: opacity 0.6s $easeOutCubic;
	}
}

.question {
	.index {
		font-size: 25px;
		font-weight: $font-weight-black;
		margin-bottom: 15px;
	}

	.upper-title {
		font-size: 18px;
		font-weight: $font-weight-bold;
		letter-spacing: 0.25em;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 15px;
	}

	.title {
		font-size: 40px;
		font-weight: $font-weight-black;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 15px;
	}

	.vs {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;

		.versus {
			font-weight: $font-weight-bold;
		}

		.slot-image {
			border-radius: 9999px;
			overflow: hidden;
		}

		.slot-name {
			font-size: 12px;
		}
	}

	&.appear,
	&.enter {
		.index,
		.upper-title,
		.title,
		.vs {
			opacity: 0;
			transform: translate(50px, 0);
		}

		.upper-title {
			--delay: 0.1s;
		}

		.title {
			--delay: 0.2s;
		}

		.vs {
			--delay: 0.3s;
		}

		.input-zone,
		.choice {
			opacity: 0;
			transform: translate(50px, 0);
		}

		@for $i from 1 to 10 {
			.choice:nth-child(#{$i}) {
				--delay: #{(0.1s * ($i - 1)) + 0.4s};
			}
		}

		.input-zone {
			--delay: 0.4s;
		}
	}

	&.appear-active,
	&.enter-active {
		.index,
		.upper-title,
		.title,
		.vs {
			--calc-delay: calc(var(--delay, 0s) + 0.5s);
			opacity: 1;
			transform: translate(0, 0px);
			transition: transform 0.6s $easeOutCubic var(--calc-delay), opacity 0.6s $easeOutCubic var(--calc-delay);
		}

		.input-zone,
		.choice {
			--calc-delay: calc(var(--delay, 0s) + 0.5s);
			opacity: 1;
			transform: translate(0, 0px);
			transition: transform 0.6s $easeOutCubic var(--calc-delay), opacity 0.6s $easeOutCubic var(--calc-delay);
		}
	}
}

.question-multiple {
	.choices {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 40px;
	}

	.choice {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 120px;
		width: 100%;
		border-radius: 10px;
		border: 2px solid transparent;
		box-shadow: inset 0 0 10px rgba($black, 0), 0 0 5px rgba($black, 0.5);
		cursor: pointer;

		--blur-amount: 10px;
		background: rgba($white, 0.2);
		-webkit-backdrop-filter: blur(var(--blur-amount));
		-moz-backdrop-filter: blur(var(--blur-amount));
		backdrop-filter: blur(var(--blur-amount));

		transition: border-color 0.3s ease, box-shadow 0.3s ease;

		.choice-text {
			font-size: 40px;
			font-weight: $font-weight-black;
			line-height: 1;
			text-transform: uppercase;
		}

		&:hover,
		&.selected {
			border-color: $color-accent-primary;
			box-shadow: inset 0 0 10px rgba($black, 0.5), 0 0 5px rgba($black, 0.5);
		}
	}
}

.question-multiple-image {
	.choices {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px 10px;
		margin-top: 30px;
	}

	.choice-text {
		margin-top: 0.5em;
		font-weight: $font-weight-bold;
	}

	.choice {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		z-index: 0;

		.choice-image {
			position: relative;
			border-radius: 10px;
			border: 2px solid transparent;
			box-shadow: inset 0 0 10px rgba($black, 0), 0 0 5px rgba($black, 0.5);
			cursor: pointer;
			width: max-content;
			overflow: hidden;

			width: 200px;
			height: 200px;

			object-fit: cover;
	
			transition: border-color 0.3s ease, box-shadow 0.3s ease;
		}

		&:hover,
		&.selected {
			.choice-image {
				border-color: $color-accent-primary;
				box-shadow: inset 0 0 10px rgba($black, 0.5), 0 0 5px rgba($black, 0.5);
			}
		}
	}
}

.question-inputs {
	.input-zone {
		margin-top: 30px;
		border-radius: 10px;
		padding: 20px 30px 10px;
		min-height: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		--blur-amount: 10px;
		background: rgba($white, 0.2);
		-webkit-backdrop-filter: blur(var(--blur-amount));
		-moz-backdrop-filter: blur(var(--blur-amount));
		backdrop-filter: blur(var(--blur-amount));

		.inputs-ctn {
			display: flex;
			justify-content: center;
			gap: 20px;
		}

		.input {
			display: flex;
			flex-direction: column;
			width: 120px;

			input {
				background: none;
				appearance: none;
				border: none;
				border-bottom: 1px solid $white;
				color: white;
				text-align: center;
				font-size: 24px;
				font-weight: $font-weight-black;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					appearance: none;
					margin: 0;
				}

				&::placeholder {
					color: inherit;
					font-weight: inherit;
				}

				&:focus {
					outline: none;
				}
			}

			label {
				text-align: center;
				font-size: 12px;
			}
		}

		[class^=button] {
			margin-top: 20px;
		}
	}
}

.all-completed {
	.title {
		@extend %title4;
		font-weight: $font-weight-black;
	}

	.completed-questions {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.completed-question {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 120px;
		width: 100%;
		border-radius: 10px;
		border: 2px solid transparent;

		--blur-amount: 10px;
		background: rgba($white, 0.2);
		-webkit-backdrop-filter: blur(var(--blur-amount));
		-moz-backdrop-filter: blur(var(--blur-amount));
		backdrop-filter: blur(var(--blur-amount));

		.answer-string {
			font-size: 40px;
			font-weight: $font-weight-black;
			line-height: 1;
			text-transform: uppercase;
			color: mix(black, white, 60%);
			text-shadow: 
				-1px -1px 0 $white,
				1px -1px 0 $white,
				-1px 1px 0 $white,
				1px 1px 0 $white;
			letter-spacing: 2px;
		}

		&.correct {
			border-color: #1cb91c;
		}

		&.incorrect {
			border-color: #c52424;
		}

		@for $i from 1 to 20 {
			&:nth-child(#{$i}) {
				--delay: #{($i) * 0.1}s;
			}
		}
	}

	&.appear,
	&.enter {
		.title,
		.completed-question {
			opacity: 0;
			transform: translate(50px, 0);
		}
	}

	&.appear-active,
	&.enter-active {
		.title,
		.completed-question {
			--calc-delay: calc(var(--delay, 0s) + 0.5s);
			opacity: 1;
			transform: translate(0, 0px);
			transition: transform 0.6s $easeOutCubic var(--calc-delay), opacity 0.6s $easeOutCubic var(--calc-delay);
		}
	}
}
