.missions {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 10px;

	.mission {
		display: flex;
		flex-direction: column;
		padding: 15px 10px;
		text-align: center;
		text-transform: uppercase;
		line-height: 1.2;
		font-size: 14px;
		position: relative;

		> * {
			position: relative;
			z-index: 1;
		}

		&:after {
			position: absolute;
			content: '';
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: black;
			border-radius: inherit;
			opacity: 0.75;
			transition: opacity 0.3s ease;
		}

		&.unlocked {
			&:after {
				opacity: 0;
			}
		}

		.name {
			margin-bottom: 20px;
			font-weight: $font-weight-bold;
		}

		.status {
			position: relative;
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			align-items: center;
			justify-items: center;
			margin-top: auto;
			margin-bottom: 15px;
		}

		.done {
			position: relative;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: $color-accent-primary;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 21px;
				height: 12px;
				background: transparent;
				border-left: 4px solid $white;
				border-bottom: 4px solid $white;
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}

		.locked {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color-accent-primary;
			grid-row: 1;
			grid-column: 1;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: $black;
			font-size: 24px;
		}

		.count {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			color: $color-accent-primary;
			font-weight: $font-weight-bold;
			grid-row: 1;
			grid-column: 1;
		}

		.circle-progress {
			--size: 58px;
			--stroke-width: 8px;
			grid-row: 1;
			grid-column: 1;
			margin: -4px 0;
		}
	}
}

.weekly-mission {
	position: relative;
	margin-top: 20px;

	.title {
		font-size: 12px;
		text-transform: uppercase;
	}

	.text {
		line-height: 1.2;
		font-size: 16px;
		color: $color-accent-primary;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		margin-top: 5px;
	}

	.time-remaining {
		position: absolute;
		top: -10px;
		right: -10px;
		background: $white;
		color: $black;
		line-height: 1;
		padding: 5px 6px;
		font-weight: $font-weight-bold;
		font-size: 16px;

		.fa-timer {
			margin-right: 5px;
		}
	}
}
