.gage {
	.bars {
		width: 450px;
		height: 60px;
		background: #0c0c0c;
		border-radius: 12px;
		align-content: center;
		.bar {
			margin-top: 6px;
			margin-bottom: 6px;
			margin-left: 4px;
			margin-right: 3px;
			width: 14px;
			height: 48px;

			background: #7f22de;
			border-radius: 3px;
			&:first-of-type {
				margin-left: 6px;
			}
			&.moved {
				background: #2e1844;
			}
		}
	}
	.breakpont-labels {
		width: 450px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 100.02%;
		/* identical to box height, or 16px */

		color: #494949;
	}
}
