.stat-bar {
	--foreground-color: #{$color-accent-primary};
	--background-color: black;
	--text-color: var(--color-text);

	position: relative;
	overflow: hidden;
	border-radius: 3px;
	width: 100%;
	height: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--background-color);
	padding: 0 6px 0;

	font-size: 12px;
	font-weight: $font-weight-bold;
	z-index: 1;

	.stat-bar-inner {
		position: absolute;
		top: 0;
		left: 0;
		background: var(--foreground-color);
		height: 100%;
		z-index: 0;
		transition: width 0.6s ease;
	}

	.stat-bar-current-level,
	.stat-bar-next-level {
		position: relative;
		z-index: 2;
	}
}
