$card-width: 240px;
$card-height: 480px;

.media {
	.page-content {
		padding: 55px 112px;
	}

	.page-title {
		margin-bottom: 30px;

		.upper-title {
			font-size: 17px;
			font-weight: $font-weight-medium;
			line-height: 1.1;
			color: #ACB4B4;
		}

		.title {
			font-size: 34px;
			font-weight: $font-weight-thin;
			line-height: 1;
		}
	}

	.show-card {
		position: relative;
		width: var(--show-card-width, #{$card-width});
		height: var(--show-card-height, #{$card-height});

		&.small {
			--show-card-width: 160px;
			--show-card-height: 320px;
			--show-details-visibility: hidden;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		.show-details {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			min-height: 160px;
			padding: 0 20px 20px;
			color: $color-text-primary;
			background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5));
			visibility: var(--show-details-visibility);
		}

		.show-title {
			font-size: 28px;
			line-height: 1.2;
			font-weight: $font-weight-bold;
			text-align: center;
		}
	}

	.main-shows-slider-wrapper {
		margin-bottom: 110px;

		.splide__track {
			overflow: visible;

			&::before {
				content: '';
				display: block;
				width: $card-width;
				height: $card-height;
				outline: 5px solid $white;

				position: absolute;
				top: 0;
				left: var(--active-slide-left, 0px);
				z-index: 2;

				pointer-events: none;
			}
		}
	}

	.favorites {
		padding-left: 160px;

		.favorites-title {
			font-size: 28px;
			font-weight: $font-weight-bold;
			margin-bottom: 0.5em;
		}

		.shows-grid {
			display: grid;
			grid-template-columns: repeat(8, 1fr);
			gap: 16px;
		}
	}

	&.enter,
	&.appear {
		.page-title,
		.splide__slide,
		.favorites {
			opacity: 0;
			transform: translate(0, 40px);
		}

		.splide__track::before {
			opacity: 0;
		}

		.splide__slide {
			--delay: calc(var(--card-index, 0) * 0.1s + 0.2s);
		}

		.favorites {
			--delay: 0.8s;
		}
	}

	&.enter-active,
	&.appear-active {
		.page-title,
		.splide__slide,
		.favorites {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}

		.splide__track::before {
			opacity: 1;
			transition: opacity 0.6s $easeOutCubic 0.8s;
		}
	}
}