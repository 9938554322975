.home {
	.upcoming-match {
		.date {
			font-size: 14px;
			font-weight: $font-weight-normal;
			line-height: 1;
			margin-bottom: 20px;
		}

		.team-name {
			font-size: 50px;
			font-weight: $font-weight-black;
			line-height: 1;
			text-transform: uppercase;
		}

		.mid {
			margin: 45px 0;
			display: flex;
			align-items: center;
			gap: 25px;

			.vs {
				text-transform: uppercase;
				font-size: 24px;
				line-height: 1;
			}
		}
	}

	.ad-space {
		margin: 55px auto 0;
	}

	.favorite-team {
		position: relative;
		color: $color-text-primary;
		text-decoration: none;
		overflow: hidden;
		border-radius: 12px;
		background: var(--team-color, linear-gradient(to bottom right, rgba(#{$color-accent-primary-rgb}, 0), rgba(#{$color-accent-primary-rgb}, 0.5)));
		display: flex;
		align-items: center;
		padding: 25px;
		width: 100%;
		height: 190px;
		flex: 0 0 auto;

		.title,
		.text,
		.chevron-icon {
			position: relative;
			z-index: 1;
		}

		.title {
			font-size: 24px;
			font-weight: $font-weight-bold;
			line-height: 1.1;
			margin-bottom: 15px;
		}

		.text {
			font-size: 14px;
			font-weight: $font-weight-normal;
			line-height: 1.2;
		}

		.chevron-icon {
			width: 10px;
			flex: 0 0 auto;
			margin-left: 50px;
			transform: translate(0, 0);
			transition: transform 0.6s $easeInOutCubic;
		}

		.logo {
			position: absolute;
			top: 50%;
			right: 25px;
			width: 160px;
			height: 100%;
			display: flex;
			align-items: center;
			transform: translate(0, -50%);
			opacity: 0.07;
			transition: transform 0.6s $easeInOutCubic;

			img {
				width: 100%;
				height: 90%;
				object-fit: contain;
			}
		}

		&:hover {
			.logo {
				transform: translate(0, -50%) translate(-25%, 0);
			}

			.chevron-icon {
				transform: translate(-10px, 0);
			}
		}
	}

	.news-feed {
		margin-top: 55px;
		flex: 0 0 auto;

		.feed {
			display: flex;
			flex-direction: column;
			gap: 30px;
			margin-top: 20px;
		}
	}

	&.enter,
	&.appear {
		.date, .team-name, .mid, .news-card {
			opacity: 0;
			transform: translate(0, 40px);
		}

		.team-name {
			--delay: 0.1s;
		}

		.mid {
			--delay: 0.2s;
		}

		.mid + .team-name {
			--delay: 0.3s;
		}

		.favorite-team {
			opacity: 0;
			transform: scale(0.5, 0.5);
			--delay: 0.4s;
		}

		@for $i from 1 to 10 {
			.news-card:nth-child(#{$i}) {
				--delay: #{$i * 0.1s + 0.3s};
			}
		}
	}

	@include media-breakpoint-down(md) {
		.upcoming-match .team-name {
			font-size: 36px;
		}
	}

	&.enter-active,
	&.appear-active {
		.date, .team-name, .mid, .news-card {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}

		.favorite-team {
			opacity: 1;
			transform: scale(1, 1);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}
	}
}