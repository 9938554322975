.chat-box {
	position: relative;
	width: 0px;
	height: 100vh;
	flex: 0 0 auto;
	transition: width 0.6s ease, box-shadow 0.6s ease;

	--width: 400px;

	box-shadow: 0 0 20px rgba(0, 0, 0, 0);
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $color-background-main;
		z-index: -1;
	}

	.mask {
		width: 100%;
		height: 100%;
		overflow-y: hidden;
		overflow-x: visible;
	}

	&.is-open {
		width: var(--width);
		box-shadow: 0 0 20px rgba(0, 0, 0, 1);

		.toggle {
			transform: translate(100%, 0);
		}
	}

	.toggle {
		position: absolute;
		top: 0;
		right: 100%;
		width: 60px;
		height: 60px;
		background: $color-accent-primary;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		cursor: pointer;
		transform: translate(0, 0);
		transition: transform 0.6s ease;
	}

	.chat-box-inner {
		width: var(--width);
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.header {
		display: flex;
		height: 60px;
		flex: 0 0 auto;

		font-size: 14px;

		align-items: center;
		justify-content: center;
		text-align: center;
		text-transform: uppercase;
		font-weight: $font-weight-bold;

		border-bottom: 1px solid rgba($color-accent-primary-rgb, 0.5);
	}

	.messages-list {
		padding: 10px 0;
		flex: 1 1 100%;
		display: grid;
		align-content: flex-start;
		overflow-y: scroll;
		overflow-x: auto;
	}

	.message {
		position: relative;
		font-size: 14px;
		margin-bottom: 10px;

		&.other-user {
			--msg-border-radius-bl: 0;
		}

		&.current-user {
			display: flex;
			justify-content: flex-end;

			--msg-border-radius-br: 0;
			--msg-background-color: #{$color-accent-primary};
			--msg-text-color: #{$color-text-primary};
			--msg-grid-layout:
				"b a"
				"c a";
		}

		&.moderated {
			opacity: 0.5;
		}

		.message-inner {
			padding: 0 10px;
			max-width: 80%;
			display: grid;
			column-gap: 10px;
			grid-template-areas: var(--msg-grid-layout,
				"a b"
				"a c"
			);
			justify-content: start;
			align-items: end;
		}

		.avatar {
			--avatar-width: 24px;
			grid-area: a;
		}

		.username {
			color: #828A8E;
			font-size: 12px;
			grid-area: b;
		}

		.message-content {
			grid-area: c;
			background-color: var(--msg-background-color, #D3D3D3);
			padding: 8px 15px;
			border-radius: 13px;
			border-bottom-left-radius: var(--msg-border-radius-bl, 13px);
			border-bottom-right-radius: var(--msg-border-radius-br, 13px);
			color: var(--msg-text-color, $gray10);
			font-size: 16px;
			line-height: 1.2;
			width: fit-content;
		}

		&.enter,
		&.appear {
			opacity: 0;
			transform: translate(0, 20px);
		}

		&.enter-active,
		&.appear-active {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.3s $easeOutCubic var(--delay, 0s), transform 0.3s $easeOutCubic var(--delay, 0s);
		}
	}

	.chat-tooltip {
		position: absolute;
		top: calc(var(--y, 0px) - 56px);
		right: calc(100% + 20px);
		z-index: 100;
		background: $color-background-main;
		padding: 12px 15px 15px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		border-radius: 20px;
		z-index: -2;

		.username {
			color: var(--color);
			font-size: 18px;
			font-weight: $font-weight-bold;
		}

		.avatar {
			position: relative;
			--avatar-width: 60px;
			margin-right: 20px;
			flex: 0 0 60px;

			.level-badge {
				position: absolute;
				bottom: 0;
				left: 50%;
				width: max-content;
				transform: translate(-50%, 50%);
			}
		}

		.favorite-team {
			display: flex;
			align-items: center;

			img {
				width: 40px;
				margin-right: 10px;
			}
		}

		.info-card-inner {
			display: flex;
			align-items: center;
			min-width: 200px;

			.info {
				flex: 1 0 auto;
			}
		}

		&.enter,
		&.appear {
			opacity: 0;
			transform: translate(100%, 0);
		}

		&.enter-active,
		&.appear-active {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}

		&.exit {
			display: none;
		}
	}

	.chat-input {
		position: relative;
		height: 60px;
		flex: 0 0 auto;
		display: flex;
		border-top: 1px solid rgba($color-accent-primary-rgb, 0.5);

		input {
			width: 100%;
			height: 100%;
			background: transparent;
			outline: none;
			padding: 5px 10px;
			border: none;
			color: white;
		}

		.emoji-picker {
			.btn {
				width: 60px;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
	
				color: $color-accent-primary;
				font-size: 24px;
			}
		}

		.picker {
			position: absolute;
			bottom: calc(100% + 10px);
			left: 50%;
			transform: translate(-50%, 0);
			border-radius: 6px;
			overflow: hidden;
			box-shadow: 0 0 10px rgba($color-accent-primary-rgb, 0.5);
		}
	}
}