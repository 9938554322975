.show {
	.page-content {
		display: flex;
		align-items: center;
	}

	.show-details {
		max-width: 50%;
	}

	.back-btn {
		color: $color-text-primary;
		font-size: 18px;
		opacity: 0.8;
		margin-bottom: 2em;
		display: inline-block;
	}

	.show-title {
		font-size: 75px;
		font-weight: $font-weight-black;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 0.5em;
		max-width: 10ch;
	}

	.tagline {
		font-size: 24px;
		font-weight: $font-weight-normal;
		line-height: 1;
		margin-bottom: 20px;
		max-width: 30ch;
	}

	.watch-btn {
		margin: 45px 0;
		font-size: 18px;
	}

	&.enter,
	&.appear {
		.back-btn, .tagline, .show-title, .watch-btn {
			opacity: 0;
			transform: translate(0, 40px);
		}

		.show-title {
			--delay: 0.2s;
		}

		.tagline {
			--delay: 0.3s;
		}

		.watch-btn {
			--delay: 0.4s;
		}
	}

	&.enter-active,
	&.appear-active {
		.back-btn, .tagline, .show-title, .watch-btn {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}
	}
}